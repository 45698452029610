import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react/hoc/connectProxy';
import {
  compose, lifecycle, branch, renderNothing,
} from 'recompose';
import { Helmet } from 'react-helmet';

import { historyShape } from 'shapes/router';
import { getProduct, getBigProduct } from 'redux/products/actions';
import {
  selectDetailedProductsMap,
} from 'redux/products/selectors';
import { postProductHistory } from 'redux/histories/actions';
import { selectDomainsMap, selectCommunitiesMap } from 'redux/communities/selectors';
import { getDomain } from 'redux/communities/actions';
import { ACTIONS as PRODUCT_ACTIONS } from 'redux/products/constants';
import { domainShape, communityShape } from 'shapes/community';
import { productShape } from 'shapes/product';

import withRouteParams from 'react/hoc/withRouteParams';
import ProductPage from 'react/pages/product/productPage/ProductPage';
import ApplicationLayout from 'react/business/layout/ApplicationLayout';
import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';

import { Redirect } from 'react-router';
import { formatRoute } from 'react-router-named-routes';
import messages from './product.messages';
import classNames from './product.module.scss';
import ROUTES from '../../routes';
import { PRODUCT_VIEW } from '../../../config/constants';

const mapRouteParamsToProps = (params => ({
  productId: params.productId,
  view: params.view,
}));

const enhancer = compose(
  withRouteParams(mapRouteParamsToProps),
  connect(
    (state, props) => {
      const product = selectDetailedProductsMap(state)[props.productId];
      const domain = selectDomainsMap(state)[product?.domainId];
      const community = selectCommunitiesMap(state)[domain?.community?.id];

      return {
        product,
        domain,
        community,
      };
    },
    {
      getProduct,
      getBigProduct,
      postProductHistory,
      getDomain,
    },
  ),

  // Load product on mount, and display nothing until loaded.
  compose(
    lifecycle({
      /** Load product on mount. */
      async componentDidMount() {
        const action = this.props.product?.parentDigitalProduct !== this.props.productId ?
          await this.props.getProduct(this.props.productId)
          : await this.props.getBigProduct(this.props.productId);

        // If the product exists, add the product to the user history and get the community/domain.
        if (action.type === PRODUCT_ACTIONS.GET_PRODUCT_SUCCESS) {
          this.props.postProductHistory(this.props.productId);
          this.props.getDomain(action.product.domainId, { populateCommunity: true });
        }
      },
    }),

    // If no product, render nothing (aka does not render the Product View).
    branch(
      props => !props.product,
      renderNothing,
    ),
  ),
);

class Product extends React.PureComponent {
  static propTypes = {
    /** From withRouteParams / Id of the product to fetch. */
    productId: PropTypes.string.isRequired,
    /** Fetched product. */
    product: productShape,
    /** Product domain. */
    domain: domainShape,
    /** Product domain community. */
    community: communityShape,
    history: historyShape.isRequired,
    view: PropTypes.string,
  };

  static defaultProps = {
    product: null,
    domain: null,
    community: null,
    view: '',
  };

  /**
   * Renders the component.
   *
   * @returns {object} - The React element.
   */
  render() {
    const {
      product,
      domain,
      community,
      view,
    } = this.props;

    // Switch view in case of trying to load a component in a product view
    if (product.parentDigitalProduct) {
      return (
        <Redirect to={
          formatRoute(
            ROUTES.COMPONENT.PRESENTATION,
            { componentId: product.id, view: PRODUCT_VIEW.OVERVIEW })
        }
        />
      );
    }

    return product ? (
      <ApplicationLayout mainClassName={classNames.container} pageType="product">
        {
          product?.name && (
            <FormattedMessageChildren
              {...messages.TITLE}
              values={{ productName: product.name }}
            >
              {message => (
                <Helmet>
                  <title>{message}</title>
                </Helmet>
              )}
            </FormattedMessageChildren>
          )
        }
        <ProductPage
          product={product}
          domain={domain}
          community={community}
          view={view}
        />
      </ApplicationLayout>
    ) : null;
  }
}

export default enhancer(Product);
